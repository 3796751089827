<template>
  <base-section id="gallery">
    <v-tabs
      v-model="tab"
      centered
      class="mb-12"
    >
      <v-tab
        v-for="(t, i) in tabs"
        :key="i"
        :href="`#${t.toLowerCase()}`"
        class="text-capitalize"
      >
        {{ t }}
      </v-tab>
    </v-tabs>

    <v-container>
      <v-fade-transition
        group
        hide-on-leave
        tag="div"
        class="row"
      >
        <v-col
          v-for="(project, i) in projects"
          :key="project.title"
          class="gallery-card"
          cols="12"
          sm="6"
          md="4"
        >
          <project-card
            v-bind="project"
            :src="project.src"
            height="300"
            width="100%"
          />
        </v-col>
      </v-fade-transition>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionGallery',

    components: {
      ProjectCard: () => import('@/components/ProjectCard'),
    },

    data: () => ({
      tab: 'All',
      tabs: [
        'All',
        'Projects',
        'News',
        'CSR',
      ],
    }),

    computed: {
      projects () {
        return [
          { title: 'Cierra Vega', subtitle: 'Projects', src: require('@/assets/project-1.jpg') },
          { title: 'Pierre Cox', subtitle: 'Projects', src: require('@/assets/project-2.jpg') },
          { title: 'Alden Cantrell', subtitle: 'Projects', src: require('@/assets/project-3.jpg') },
          { title: 'Kierra Gentry', subtitle: 'Projects', src: require('@/assets/project-4.jpg') },
          { title: 'Thomas Crane', subtitle: 'Projects', src: require('@/assets/project-5.jpg') },
          { title: 'Miranda Shaffer', subtitle: 'News', src: require('@/assets/project-6.jpg') },
          { title: 'Bradyn Kramer', subtitle: 'News', src: require('@/assets/project-7.jpg') },
          { title: 'Bailey Wolfe', subtitle: 'CSR', src: require('@/assets/project-8.jpg') },
          { title: 'Lorelei Holloway', subtitle: 'CSR', src: require('@/assets/project-9.jpg') },
          { title: 'Justine Elliott', subtitle: 'CSR', src: require('@/assets/project-10.jpg') },
        ].filter(project => {
          if (this.tab.toLowerCase() === 'all') return true

          return project.subtitle.toLowerCase() === this.tab.toLowerCase()
        })
      },
    },
  }
</script>

<style lang="sass">
  .gallery-card
    transform-origin: center center 0
    transition: .3s cubic-bezier(.25,.8,.50,1)
</style>
